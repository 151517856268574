
<template>
  <div id="user-edit-tab-info">
    <h5 class="mb-3 text-uppercase bg-light p-2">
      <i class="mdi mdi-account-circle mr-1"></i> Personal Info
    </h5>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group mb-3">
          <label>Country</label>
          <multiselect :options="countries.map(c => c.id)" :custom-label="parseCountry" v-model="data.country_id" placeholder="--select--"></multiselect>
          <span class="text-danger invalid-feedback small" v-show="errors.has('country')">{{ errors.first('country') }}</span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Mobile</label>
          <div class="input-group">
            <div class="input-group-prepend">
                <span class="input-group-text">
                  <img v-if="selectedCountry" width="20" class="me-1" :src="absoluteUrl(`/images/flags/${selectedCountry.icon}`)" />
                  <span>{{selectedCountry ? selectedCountry.phone_code : '+xxx'}}</span>
                </span>
            </div>
            <input type="text" v-maska="'(###) ###-####'" v-model="data.mobile" v-validate="{ required: true }"  class="form-control"
            name="phone" placeholder="Phone number" :class="{ 'is-invalid': errors.has('phone') }">
          </div>
          <span class="text-danger invalid-feedback small"  v-show="errors.has('phone')">{{ errors.first('phone') }}</span>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="streetAddress" class="form-label">Street address</label>
          <input v-model="data.address" type="text" class="form-control"  name="address"
          :class="{ 'is-invalid': errors.has('address') }" v-validate="{ required: true }"
              id="streetAddress" placeholder="Street address">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="city" class="form-label">City</label>
          <input v-model="data.city" type="text" class="form-control" name="city"
          :class="{ 'is-invalid': errors.has('city') }" v-validate="{ required: true }"
          id="city" placeholder="City">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="state" class="form-label">State</label>
          <input v-model="data.state" type="text" class="form-control" name="state"
          :class="{ 'is-invalid': errors.has('state') }" v-validate="{ required: true }"
          id="state" placeholder="State">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="zip" class="form-label">Zip</label>
          <input v-model="data.zip" type="number" class="form-control"  name="zip"
          :class="{ 'is-invalid': errors.has('zip') }" v-validate="{ required: true }"
              id="zip" placeholder="Zip">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group mb-3">
          <label class="d-block">Date of Birth</label>
          <date-picker v-model="data.dob" :editable="false" :first-day-of-week="1" placeholder="choose date" format="YYYY-MM-DD" lang="en"></date-picker>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="userbio">About</label>
          <textarea id="userbio"  v-model="data.about" class="form-control" rows="3" placeholder="Write something..." v-validate="'max:1000'" name="about"></textarea>
          <span class="text-danger font-sm"  v-show="errors.has('about')">{{ errors.first('about') }}</span>
        </div>
      </div>
    </div>
    <h5 class="mb-3 text-uppercase bg-light p-2">
      <i class="mdi mdi-earth mr-1"></i> Social
    </h5>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label for="social-fb">Facebook</label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <i class="fab fa-facebook-square"></i>
                        </span>
                    </div>
                    <input v-model="data.facebook" id="social-fb" type="text" class="form-control" placeholder="Url" v-validate="'url:require_protocol'" name="facebook" />
                </div>
                <span class="text-danger font-sm"  v-show="errors.has('facebook')">{{ errors.first('facebook') }}</span>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="social-tw">Twitter</label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <i class="fab fa-twitter"></i>
                        </span>
                    </div>
                    <input  v-model="data.twitter" id="social-tw" type="text" class="form-control" placeholder="url" v-validate="'url:require_protocol'" name="twitter"  />
                </div>
                <span class="text-danger font-sm"  v-show="errors.has('twitter')">{{ errors.first('twitter') }}</span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label for="social-insta">Instagram</label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <i class="fab fa-instagram"></i>
                        </span>
                    </div>
                    <input v-model="data.instagram" id="social-insta" type="text" class="form-control" placeholder="Url" v-validate="'url:require_protocol'" name="instagram"/>
                </div>
                <span class="text-danger font-sm"  v-show="errors.has('instagram')">{{ errors.first('instagram') }}</span>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="social-gh">Github</label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <i class="fab fa-github"></i>
                        </span>
                    </div>
                    <input  v-model="data.github" id="social-gh" type="text" class="form-control" placeholder="url" v-validate="'url:require_protocol'" name="github" />
                </div>
                <span class="text-danger"  v-show="errors.has('github')">{{ errors.first('github') }}</span>
            </div>
        </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-md-6">
        <div class="text-right">
          <button type="button" @click.prevent="updateUser()" class="btn btn-success mt-2">
              <i class="mdi mdi-content-save"></i> Update
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import DatePicker from 'vue2-datepicker'
import Multiselect from 'vue-multiselect'
import { maska } from "maska"

export default {
  components: {
    DatePicker,
    Multiselect,
  },
  data() {
    return {
      data:{
        about: "",
        dob: null,
        country_id: null,
        mobile: "",
        twitter: "",
        facebook: "",
        instagram: "",
        github: "",
        address: "",
				city: "",
				state: "",
				zip: "",
      },
    }
  },
  directives: { maska: maska },
  computed: {
    countries(){
      return this.$store.state.countries;
    },
    user(){
      return this.$store.state.userList.user
    },
    selectedCountry(){
      if(!this.data.country_id) return null
      const country = this.countries.find(i => i.id ==  this.data.country_id)
      return country ? country : null;
    }
  },
  methods: {
    parseCountry(id){
      let con = this.countries.find(item => item.id == id);
      if(con){ return con.name; }
    },
    updateUser(){
      this.$validator.validateAll().then(result => {
        if (!result) { this.alertError("Form Not Valid"); return;}
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(`/users/${this.user.id}/update-info`,formData)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
        })
      });
    },
    patchUser(){
      const address = this.user.address || {}
      this.data = this.$options.filters
                      .patchUpdatables(this.data, {...this.user, ...address})
      this.data.dob = this.data.dob ? new Date(this.data.dob) : null
    }
  },
  created(){
    this.patchUser()
  },
}
</script>
