
<template>
  <div id="page-user-rdit">
    <div v-if="!isLoading">
      <div class="row">
        <div class="col-lg-4 order-lg-2 col-xl-4">
            <div class="card">
              <div class="card-body text-center">
                <img :src="absoluteUrl(shownUser.image)" class="rounded-circle avatar-xl img-thumbnail" alt="profile-image" />

                <h4 class="mt-3 mb-0" v-if="shownUser.role == 'buyer'">{{shownUser.first_name}} {{shownUser.last_name}}</h4>
                <h4 class="mt-3 mb-0" v-if="shownUser.role == 'worker'">{{shownUser.username}}</h4>
                <p class="text-muted">Type: {{shownUser.role}}</p>
                <button type="button" class="btn mr-1 btn-success btn-xs waves-effect mb-2 waves-light">
                  {{shownUser.status}}
                </button>
                <span>
                <button type="button" class="btn btn-xs waves-effect mb-2 waves-light" :class="shownUser.is_verified ? 'btn-info' : 'btn-danger'">
                  {{shownUser.is_verified ? 'Verified' : 'Not Verified'}}
                </button>
                </span>
                <div>
                  <router-link class="btn btn-outline-info mr-2" :to="`/chats/${shownUser.id}`"> Chat <i class="mdi mdi-chat"></i></router-link>
                  <!-- <router-link class="btn btn-outline-dark mr-2" :to="`/marketing?user_id=${shownUser.id}`"> Mail <i class="mdi mdi-email-edit-outline"></i></router-link> -->
                </div>
              </div>
            </div>
            <div v-if="1 == 10" class="card">
              <div class="card-body">
                  <div class="border-bottom pb-2 mb-2">
                      <p class="mb-1">TOTAL INCOME</p>
                      <h3 class="my-0 text-success">${{earning.income | money_format}}</h3>
                  </div>
                  <div  class="border-bottom pb-2 mb-2">
                      <p class="mb-1">WORKER GAIN</p>
                      <h3 class="my-0 text-success">${{earning.gain | money_format}}</h3>
                  </div>
                  <div class="border-bottom pb-2 mb-2">
                      <p class="mb-1">WITHDRAWN</p>
                      <h3 class="my-0 text-success">${{earning.withdrawn | money_format}}</h3>
                  </div>
                  <div class="">
                      <p class="mb-1">PROJECTS WON</p>
                      <h3 class="my-0 text-success">{{shownUser.projects_won.length}}</h3>
                  </div>
              </div>
            </div>
        </div>
        <div class="col-lg-8 col-xl-8">
            <div class="card">
                <div class="card-body">
                    <b-tabs content-class="mt-1" pills class="navtab-bg">
                        <b-tab active>
                            <template v-slot:title>
                                <i class="mdi mdi-account-check mr-1"></i> Account
                            </template>
                            <user-edit-tab-account />
                        </b-tab>
                        <b-tab>
                          <template v-slot:title>
                              <i class="mdi mdi-account-cog mr-1"></i> Settings
                          </template>
                           <user-edit-tab-information />
                        </b-tab>
                        <b-tab>
                          <template v-slot:title>
                              <i class="mdi mdi-lock-check mr-1"></i> Password
                          </template>
                          <user-edit-tab-password />
                        </b-tab>
                    </b-tabs>
                </div>
                <!-- end card-box-->
            </div>
        </div>
      </div>
      <div v-if="shownUser.role == 'worker'" class="row mt-4">
        <div v-if="shownUser.earning_data" class="col-lg-8 col-xl-8">
          <div>
            <earning-overview :earning="shownUser.earning_data.overview" />
          </div>
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <h4 class="header-title">Earnings</h4>
                <div class="btn-group mb-2">
                  <button type="button" class="btn btn-xs btn-light active">
                      Today
                  </button>
                  <button type="button" class="btn btn-xs btn-light">Monthly</button>
                </div>
              </div>
              <worker-earnings :series-data="shownUser.earning_data.series" />
            </div>
          </div>
        </div>
        <div v-if="shownUser.delivery_data" class="col-lg-8 col-xl-8">
          <div>
            <delivery-result :result="shownUser.delivery_data" />
          </div>
        </div>
      </div>
    </div>
    <is-loading v-else />
  </div>
</template>

<script>
import IsLoading     from "@/components/IsLoading.vue"
import UserEditTabAccount     from "./UserEditTabAccount.vue"
import UserEditTabInformation from "./UserEditTabInformation.vue"
import UserEditTabPassword from "./UserEditTabPassword.vue"
import ApplicationDetails from '@/components/user/ApplicationDetails.vue'
import WorkerEarnings from '@/components/user/WorkerEarnings.vue'
import EarningOverview from '@/components/user/EarningOverview.vue'
import DeliveryResult from '@/components/user/DeliveryResult.vue'

export default {
  components: {
    IsLoading,
    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabPassword,
    ApplicationDetails,
    WorkerEarnings,
    EarningOverview,
    DeliveryResult
  },
  data() {
    return {
      isLoading: true,
    }
  },
  watch: {
    '$route.params.userId': function (id){
      if(id) this.fetchUser()
    },
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    shownUser(){
      return this.$store.state.userList.user
    },
    earning(){
      return this.shownUser.earning
    },
  },
  methods:{
    fetchUser(){
      this.isLoading = true
      this.$store.dispatch("userList/fetchUser", this.$route.params.userId)
      .then((response) => {
        this.isLoading = false
        if(!response.data.success){
          this.$router.push({path: '/error-404'})
        }
      })
    }
  },
  created() {
    this.$store.dispatch("userList/fetchRoles")
    this.fetchUser()
  }
}

</script>
