<template>
  <div v-if="item">
    <div class="mb-4">
        <div class="d-lg-flex mb-3">
            <div class="mb-3 mb-md-0">
                <img :src="absoluteUrl(item.job.image)" :alt="item.job.name" width="60" height="60" class="icon-shape border rounded-circle">
            </div>
            <div class="ml-lg-3 w-100 mt-3 mt-lg-1">
                <div class="d-flex justify-content-between">
                    <div>
                        <h3 class="mb-1 fs-4">
                            <span class="text-inherit fs-3 fw-bold">{{item.job.name}}</span>
                        </h3>
                        <div>
                            <span>{{item.job.description}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-md-flex justify-content-between">
            <div v-if="item.job.tools" class="">
                <span class="badge badge-light mr-2" v-for="(tool, tIndex) in item.job.tools.split(',')"
                :key="tIndex">{{tool}}</span>
            </div>
        </div>
    </div>
    <div class="row mb-3 mb-lg-4">
        <div class="col-lg-6 mb-2">
            <span><strong>Years of experience</strong>: {{item.yrs_of_experience}} </span>
        </div>
        <div class="col-lg-6 mb-2">
            <span><strong>Currently employed</strong>: {{item.is_employed ? 'YES' : 'NO'}} </span>
        </div>
        <div class="col-lg-6 mb-2">
            <span><strong>When to start</strong>:  {{item.start_date | date}} </span>
        </div>
        <div class="col-lg-6 mb-2">
            <span><strong>Portfolio link</strong>: <a :href="item.portfolio_url" target="_blank"> {{item.portfolio_url}}</a> </span>
        </div>
        <div class="col-lg-6 mb-2">
            <span><strong>Resume link</strong>: <a :href="item.resume_url" target="_blank"> {{item.resume_url}} </a> </span>
        </div>
        <div class="col-lg-6 mb-2">
            <span><strong>Where you found us</strong>: {{item.found_us_from}} </span>
        </div>
            <div class="col-lg-6 mb-2">
            <span><strong>Internet perfomance</strong>: {{item.mbps}} </span>
        </div>
        <div class="col-lg-12">
            <span><strong>Message</strong>: {{item.message}} </span>
        </div>
    </div>
    <div class="row mb-3 mb-lg-4">
        <div class="col-12">
            <h4>Assessment</h4>
            <div v-html="item.assessment" class="assessment-view" ></div>
        </div>
    </div>
    <div class="mb-3 mb-lg-4">
        <view-attachments :files="item.files" />
    </div>
    <div class="mb-3 mb-lg-4">
        <span class="h4"><strong>Current status</strong>: {{item.status}} </span>
    </div>
    <div class="">
       <button v-if="item.status != 'PENDING'" @click="updateStatus('PENDING')" class="btn btn-sm btn-dark mr-2">Pending</button>
       <button v-if="item.status != 'ACCEPTED'" @click="updateStatus('ACCEPTED')" class="btn btn-sm btn-primary mr-2">Accept</button>
       <button v-if="item.status != 'REJECTED'" @click="updateStatus('REJECTED')" class="btn btn-sm btn-danger">Reject</button>
    </div>
  </div>
</template>

<script>
import ViewAttachments from '@/components/ViewAttachments.vue'

export default {
    components: { ViewAttachments },
    props:{
        item:{
            type: Object,
            default: null
        }
    },
    methods:{
        updateStatus(status){
            const formData = new FormData()
            formData.append('data', JSON.stringify({status: status}))
            Swal.fire({
                title: "Are you sure?",
                text: `You are about to ${status} this application!`,
                reverseButtons:true,
                showCancelButton: true,
                confirmButtonColor: "#28a745",
                cancelButtonColor: "#323a46",
                confirmButtonText: "Proceed!",
            }).then((result) => {
                if (result.value) {
                    this.$store.dispatch("changeLoaderValue",true)
                    this.$http.post(`/applications/${this.item.id}/update`, formData)
                        .then((response) => {
                        this.$store.dispatch("changeLoaderValue")
                        if(response.data.success){
                            this.$store.dispatch("userList/fetchUser", this.item.applicant_id)
                        }
                    })
                }
            });
        },
    },
}   
</script>

<style>
.assessment-view *{
 margin: 0;
}
</style>