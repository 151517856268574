<template>
  <div class="card shadow-sm show-worker-earnings border">
        <div class="card-body">
            <div class="row font-weight-bold justify-content-between">
                <div class="col-sm-3 mb-3 mb-sm-0">
                    <p class="mb-1">LAST MONTH</p>
                    <h2 class="mb-0 text-success">${{earning.last_month | money_format}}</h2>
                </div>
                <div  class="col-sm-9">
                    <p class="mb-1">CURRENT MONTH</p>
                    <h4 class="mb-1">${{earning.current_month | money_format}}</h4>
                    <div class="mb-1">
                        <div class="progress" style="height: 3px;">
                            <div role="progressbar" aria-valuemax="100"
                                class="progress-bar" :style="currentMonthStyle(earning.current_month)"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        earning:{
            default:() => ({
                "last_month": 0,
                "current_month": 0,
            })
        }
    },
    computed:{
        currentMonthStyle(){
            return (v) => {
                return {
                    width: v < 51 ? "30%" : v < 101 ? "50%" : "90%",
                    backgroundColor: v < 51 ? "red" : v < 101 ? "orange" : "green",
                }
            }
        }
    },
}
</script>

<style scoped>
.show-worker-earnings h2{
    font-weight: bold;
}
@media(max-width:567px){
    .show-worker-earnings h2{
        font-size:22px;
    }
    .show-worker-earnings p{
        font-size:14px;
    }
}
</style>