<template>
<div class="card">
    <div class="card-body">
        <h4 class="header-title mb-1">Worker delivery summary</h4>
        <p class="mb-3"> Worker delivered on {{result.total}} order{{pluralize(result.total)}}.</p>
        <div class="table-responsive mb-0">
            <table class="table table-sm table-centered mb-0 font-14">
                <thead class="thead-light">
                    <tr>
                        <th>Status</th>
                        <th>Count</th>
                        <th style="width: 30%;"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>In Progress</td>
                        <td>{{result.progress}}</td>
                        <td>
                            <div class="progress" style="height: 3px;">
                                <div role="progressbar" aria-valuemax="100"
                                    class="progress-bar" :style="`width: ${getDevInp(result.progress)}%;`"></div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Finalist</td>
                        <td>{{result.finalist}}</td>
                        <td>
                            <div class="progress" style="height: 3px;">
                                <div role="progressbar" aria-valuemax="100"
                                    class="progress-bar bg-info" :style="`width: ${getDevInp(result.finalist)}%;`"></div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Won</td>
                        <td>{{result.winner}}</td>
                        <td>
                            <div class="progress" style="height: 3px;">
                                <div role="progressbar" aria-valuemax="100"
                                    class="progress-bar bg-success" :style="`width: ${getDevInp(result.winner)}%;`"></div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Blocked</td>
                        <td>{{result.blocked}}</td>
                        <td>
                            <div class="progress" style="height: 3px;">
                                <div role="progressbar" aria-valuemax="100"
                                    class="progress-bar bg-danger" :style="`width: ${getDevInp(result.blocked)}%;`"></div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Eliminated</td>
                        <td>{{result.eliminated}}</td>
                        <td>
                            <div class="progress" style="height: 3px;">
                                <div role="progressbar" aria-valuemax="100"
                                    class="progress-bar bg-warning" :style="`width: ${getDevInp(result.eliminated)}%;`"></div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props:{
        result:{
            type: Object,
            required: true
        }
    },
    computed:{
        activeUser(){
            return this.$store.state.AppActiveUser
        },
        pluralize(){
            return (i) => i > 1 ? 's':''
        },
        pluralizeIsAre(){
            return (i) => i > 1 ? 'are':'is'
        },
        getDevInp(){
            return (v) => Math.round(v/(this.result.total*0.01))
        },
    },
}
</script>

<style>

</style>